@media (max-width: 991.98px) {
    h1 {
        font-size: 24px;
    }

    h3 {
        font-size: 16px;
    }

    h2 {
        font-size: 16px;
    }
.logo{
    width: 250px;
    height: 80px;
}
    .container {
        width: 95%;
        margin: 0 auto 32px;
        min-width: unset;
    }

    .box {
        padding: 0;
    }

    nav {
        flex-wrap: wrap;
    }

    .mobile-menu-btn,
    .mobile-filter-btn {
        display: block;
    }

    .nav-menu,
    .nav-right .lang {
        display: none;
    }

    .nav-right,
    .nav-right label {
        width: 100%;
    }

    footer {
        padding-top: 16px;
        margin-top: 0;
    }

    footer>* {
        font-size: 14px;
    }

    footer .container {
        margin-bottom: 0;
    }

    footer .footer-top {
        flex-direction: column;
    }

    footer .footer-top .left {
        margin: auto;
        width: 75%;
        text-align: center;
    }

    footer .footer-top .left .icons {
        justify-content: center;
    }

    footer .footer-top .right {
        width: 100%;
        flex-direction: column;
        margin-top: 32px;
    }

    footer .footer-top .right .item {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #606060;
    }

    footer .map {
        margin-top: 0;
        text-align: left;
    }

    footer .map p {
        margin-bottom: 8px;
    }

    footer .map iframe {
        aspect-ratio: 5 / 3;
    }

    .kisa-melumat .picture {
        border-radius: 16px 16px 0 0;
        aspect-ratio: 3 / 1;
    }

    .kisa-melumat .picture .text {
        width: 85%;
        margin: auto 0;
    }

    .kisa-melumat .info {
        padding: 16px;
    }

    .teklifler .left {
        width: 100%;
    }

    .teklifler .left h1 {
        padding: 16px 16px 0;
    }

    .teklifler .left span {
        padding: 0 16px 16px;
        display: inline-block;
        cursor: pointer;
    }

    .teklifler .left p {
        transition: 0.6s;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 22px;
        padding: 0 16px;
        margin-bottom: 8px;
        -webkit-line-clamp: 3;
    }

    .show-text {
        display: block !important;
    }

    .teklifler  .photo-list {
        overflow-x: scroll;
        display: block;
        white-space: nowrap;
        border-radius: 0 0 16px 16px;
    }

    .teklifler  .photo-list img {
        width: 45%;
        aspect-ratio: 8 / 10;
        border-radius: 0;
    }

    .teklifler .right {
        /* display: none; */
    }
    .teklifler .left .photo-list{
        display: none;
    }
    .zemanet .left {
        width: 100%;
        overflow: hidden;
        border-radius: 16px 16px 0 0;
        aspect-ratio: 3 / 1;
        display: flex;
        align-items: center;
    }

    .zemanet .left img {
        height: 250%;
    }

    .zemanet .right {
        width: 100%;
        padding: 16px;
    }

    .zemanet .right .item {
        width: 100%;
        padding: 0;
        padding-right: 50px;
    }

    .zemanet .right .item:not(:last-of-type) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #EBEBEB;
    }
    .about{
        grid-template-columns: 1fr;
    }
    .about .left {
        width: 100%;
        margin: 0;
        padding: 16px;
    }

    .about .left h2 {
        font-size: 20px;
    }

    .about .right {
        width: 100%;
        aspect-ratio: 4/3;
        border-radius: 0 0 16px 16px;
        overflow: hidden;
    }

    .faq {
        box-shadow: none;
    }

    .faq-top {
        width: 75%;
    }

    .faq-item .left {
        max-height: 42px;
    }

    .faq-item .left h4 {
        -webkit-line-clamp: 2;
    }

    .services-filter {
        display: none;
    }

    .services .right,
    .service-info .right {
        width: 100%;
    }

    /* .services .right h3,
    .related-topics h3 {
        font-size: 16px !important;
        } */

    .services .services-list {
        gap: 16px;
    }

    .services .service-item {
        width: calc((100% - 32px) / 3);
    }

    .service-item {
        padding: 8px;
    }

    .service-item .text {
        margin-top: 8px;
    }

    .service-info .right {
        box-shadow: none;
    }

    .service-info .review {
        flex-wrap: wrap;
    }

    .service-info .review .small-images {
        width: 18%;

    }

    .service-info .review .big-image {
        width: 80%;
        aspect-ratio: 3 / 2;
        border-radius: 8px;
    }

    .service-info .review .text {
        width: 100%;
        margin-top: 16px;
    }

    .activity-item {
        padding: 16px;
    }

    .activity .activity-list {
        gap: 16px;
    }

    .activity .activity-item {
        width: calc((100% - 16px) / 2);
    }

    .activity-item .ust p:first-of-type {
        margin-bottom: 0px;
    }
}