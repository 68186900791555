@media (max-width: 767.98px) {
.container_fealiyetler {
    display: grid;
    grid-template-columns: 1fr;
}
    .container {
        width: 90%;
    }

    footer .footer-top .left {
        width: 100%;
    }

.teklifler .left{
    grid-template-columns: 1fr;
}
    .kisa-melumat .picture {
        aspect-ratio: 4 / 3 !important;
    }

    .kisa-melumat .info {
        flex-direction: column;
        gap: 16px;
    }

    .kisa-melumat .info h3 {
        text-align: center;
    }

    .kisa-melumat .info .right {
        width: 100%;
        justify-content: center;
        padding: 0 30px;
    }

    .kisa-melumat .info .right .item {
        padding: 0;
    }

    .teklifler .left .photo-list img {
        width: 85%;
    }

    .teklifler .right {
        /* display: none; */
    }
.teklifler .left .photo-list{
    display: none;
}
nav .logo {
    display: none;
}
.logoMobile{
    display: block;
    height: 70px;
    width: 145px;
}
.logoMobile img{
    width: 100%;
    height: 100%;
}

    .zemanet .left {
        aspect-ratio: 10 / 9;
    }

    .zemanet .left img {
        height: 100%;
    }
    .about{
        grid-template-columns: 1fr;
    }
    .about .right {
        aspect-ratio: 4/3;
    }

    .faq-top {
        width: 100%;
        padding: 0 16px;
    }
    .services .service-item {
        width: calc((100% - 16px));
        height: 100%;
    }
    .activity .activity-item {
        width: 100%;
    }
    .serviceEror h3{
        font-size: 25px;
    }
    .serviceEror strong{
        font-size: 16px;
    
    }
}