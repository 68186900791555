.login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.login form {
    width: 30%;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.login label,
.login-checkbox {
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.login input:not([type="checkbox"]) {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #CBCBCB;
    padding: 14px 16px;
    outline: none;
}

.login-checkbox .checkmark {
    width: 20px;
    height: 20px;
    border: 1px solid #CBCBCB;
    border-radius: 4px;
    background-color: white;
    transition: background-color 0.3s;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-checkbox .checkmark span {
    display: none;
}

.login-checkbox input[type="checkbox"] {
    display: none;
}

.login-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: #019934;
    border-color: #019934;
}

.login-checkbox input[type="checkbox"]:checked+.checkmark span {
    display: inline-block;
}

.login button {
    width: 100%;
}

.admin-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
}

.admin-nav .right {
    display: flex;
    gap: 16px;
    align-items: center;
}

.user-logo {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.bottom-page {
    display: flex;
}

.dashboard {
    font-size: 14px;
    width: 20%;
    font-weight: 600;
    padding: 32px;
}

.dashboard-item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    width: 100%;
    padding: 8px 16px;
    color: #151515;
    margin-bottom: 16px;
}

.dashboard-item.active {
    background: #019934;
    color: white;
}

.dashboard-item.active .bi-chevron {
    transform: rotate(90deg);
}

.dashboard-item .bi-chevron {
    margin-left: auto;
}

.dropdown-link {
    padding: 8px 40px;
}

.dropdown-link.active {
    color: #019934;
}

.dropdown-link:not(:last-of-type) {
    margin-bottom: 8px;
}

.dropdown-bottom {
    display: block;
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0;
}

.dropdown-bottom.active {
    max-height: 1000px;
    margin-bottom: 16px;
}

.btn {
    padding: 8px 16px;
    border-radius: 12px;
    background-color: #019934;
    color: white;
    border: 1px solid #019934;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.bottom-page .right-content {
    padding: 32px;
    width: 80%;
    min-height: 100vh;
    background-color: #F3F3F3;
}

.right-content .box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    background-color: white;
    padding: 0;
}

.form-item {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.form-item-img {
    flex-wrap: nowrap;
}

.form-item input,
.form-item textarea {
    width: 100%;
    padding: 16px;
    background-color: #F3F3F3;
    outline: none;
    border-radius: 12px;
    border: 1px solid #F3F3F3;
}

.form-item input:focus,
.form-item textarea:focus {
    background-color: white;
    border: 1px solid #CBCBCB;
}

.bi-trash3 {
    color: #EF4358;
}

.form-item span {
    border: 1px solid #CBCBCB;
    border-radius: 16px;
    padding: 8px;
    overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}


.form-box {
    display: flex;
    gap: 32px;
    width: 100%;
}

.form-box .form-item {
    width: calc((100% - 32px) / 2);
}

.add-img {
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    border-radius: 12px;
    align-items: center;
    background-color: #F3F3F3;
    width: fit-content;
    height: fit-content;
}

.w-40 {
    width: 40%;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.w-60 {
    border-left: 1px solid #CBCBCB;
    padding: 24px;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.container-3 .w-60 {
    flex-direction: column;
}

.container-4 .w-60 {
    border-left: none;
    border-right: 1px solid #CBCBCB;
}

.top {
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid #CBCBCB;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.edit-footer .top,
.add-product .top {
    padding: 0;
    gap: 0;
}

.edit-footer .bottom,
.add-product .bottom {
    padding: 24px;
    width: 100%;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
}

.add-product .container-1 .btn,
.add-faq .btn {
    font-size: 14px;
    margin-left: auto;
}

.add-img-box {
    width: 100%;
}

.add-product .bottom-main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.add-activity .form-box,
.edit-about .form-box {
    flex-wrap: wrap;
    gap: 16px;

}

.add-activity .form-box .form-item,
.edit-about .form-box .form-item {
    width: 100%;
}

.edit-user .box {
    padding: 24px;
    gap: 16px;
}

.edit-user .form-box {
    flex-wrap: wrap;
    gap: 16px;
}

.edit-user .form-item {
    width: 100%;
}

.add-faq .box {
    padding: 24px;
    gap: 16px;
}

.follow-list .box {
    width: 100%;

}

.follow-products .top {
    padding: 24px;
    border-bottom: 1px solid #CBCBCB;
}

.follow-products .bottom {
    padding: 24px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
}

.follow-activity .box {
    gap: 24px;
    padding: 24px;
}

.follow-item {
    width: calc((100% - 24px) / 2);
    height: fit-content;
    border-radius: 12px;
    border: 1px solid #A0A0A0;
    display: flex;
}

.follow-item li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    min-width: 50px;
    font-size: 14px;

}

.follow-item li:not(:last-of-type) {
    border-right: 1px solid #A0A0A0;
}

.follow-item img {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.select-box {
    border: 1px solid #A0A0A0;
    border-radius: 8px;
    width: 40%;
    overflow: hidden;
}
.add-product .select-box{
    width: 100%;
}

.select-box .select {
    padding: 10px 16px;
    border-bottom: 1px solid #A0A0A0;
}

.select-box .select-items {
    overflow-y: scroll;
    height: 220px;
}

/* Для веб-китов (Chrome, Safari) */
.select-items::-webkit-scrollbar {
    width: 8px;
}

.select-items::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
}

.select-items::-webkit-scrollbar-thumb {
    background: #CBCBCB;
    border-radius: 8px;
}

.select-items::-webkit-scrollbar-thumb:hover {
    background: #CBCBCB;
}

.select-box .select-item {
    padding: 10px 16px;
}