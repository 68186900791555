* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

a,
p {
    text-decoration: none;
    color: #606060;
    transition: 0.4s;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 600;
    color: #151515;
    transition: 0.4s;
}

h1 {
    font-size: 32px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 16px;
}

ul {
    list-style: none;
}

img {
    vertical-align: middle;
}
.is-sticky{
    position: fixed;
    z-index: 99999;
    width: 100%;
    left: 0;
    top: 0;
    background: white;
    padding-bottom: 10px;
}
.container {
    width: 70%;
    min-width: 1200px;
    margin: 50px auto 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
}

.box {
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 8px 1px #26262629;
    
}
.rightInfo{
    width: 100% !important;
}
.wp-link-icon {
    position: fixed;
    bottom: 32px;
    right: 30px;
    z-index: 99999;
}

nav .container {
    margin-top: 23px;
    justify-content: space-between;
    align-items: center;
}

nav .logo {
    display: flex;
    width: 200px;
}

nav .logo img {
    width: 100%;
}

.mobile-menu-btn,
.mobile-filter-btn {
    display: none;
    cursor: pointer;
}
.btn{
    cursor: pointer;
}
.mobile-menu {
    transition: 0.4s;
    position: fixed;
   
    right: 0;
    top: 130px;
    bottom: 0;
    background-color: white;
    z-index: 101;
    width: 0;
    min-width: 0;
}

.mobile-menu-activ {
    width: 40%;
    min-width: 300px;
}

.mobile-menu .nav-link {
    margin-bottom: 30px !important;
    text-transform: uppercase;
    display: inline-block;
    color: #606060;
    font-weight: 500;
}

.media-lang-back {
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    gap: 16px;
    align-items: center;
    padding-bottom: 24px;
}
.lang ul{
    position: absolute;
}
.lang p{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}
.lang i{
    transition: .6s;
}
.categoryActive span{
    color: #019934 !important;
}
.media-lang-item {
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 0px 8px 1px #26262629;
    margin-bottom: 16px;
    font-weight: 500;
}

.media-lang-item.active {
    color: #019934;
    box-shadow: 0px 0px 8px 1px #0199343D;
}
.rotate {
    transform: rotate(180deg);
  }
.mobile-filter {
    transition: 0.4s;
    position: fixed;
    left: -100%;
    top: 130px;
    bottom: 0;
    background-color: white;
    z-index: 101;
    width: 0;
    min-width: 0;
}
.serviceEror{
    display: flex;
    flex-direction: column;
    /* justify-content: center;/ */
    /* row-gap: 20px; */
    /* align-items: center; */
    width: 100%;
    
}
.serviceEror h3,strong{
    color: red;
    font-weight: 500;
}
.services h3{
    font-size: 30px;
}
.serviceEror h3{
    font-size: 30px;
}
.serviceEror strong{
    font-size: 20px;

}
.mobile-swip-item {
    padding: 32px 24px;
    display: none;
}

.mobile-filter .bottom .filter-top {
    margin-top: 10px;
    
}

.mobile-filter-activ {
    left: 0;
    width: 40%;
    min-width: 300px;
}

.mobile-filter-activ .mobile-swip-item,
.mobile-menu-activ .mobile-swip-item {
    display: block !important;
}

.nav-menu {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
}
.exitAdmin{
    display: flex;
    align-items: center;
    gap: 5px;
    color: red;
    padding: 8px 16px;
    font-size: 18px;
    cursor: pointer;
}
.nav-link {
    position: relative;
    transition: 0.4s;
    border-bottom: 0;
    height: 24px;
    top: 0;
}

.nav-link:hover {
    transition: 0.4s;
    border-bottom: 1px solid #019934;
    color: #019934;
    top: 2px;
}

.nav-link.active {
    color: #019934;
}

.nav-right {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 33.3%;
}

.nav-right label {
    position: relative;
    display: flex;
    align-items: center;
    border: 0;
    border-bottom: 1px solid #A0A0A0;
    gap: 20px;
    color: #A0A0A0;
    width: 88%;
}

.focused {
    border-bottom: 1px solid #019934 !important;
}

.focused svg {
    color: #019934 !important;
}

.nav-right label svg {
    position: absolute;
    right: 0;
}

.nav-right label span {
    left: 0;
    position: absolute;
    transition: 0.5s;
}

.nav-right input {
    border: 0;
    opacity: 0;
    outline: none;
    background-color: transparent;
    transition: 0.5s;
    width: 100%;
}


.nav-right .lang span {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    color: #151515;
    font-weight: 500;

}

.nav-right .lang {
    position: relative;
}

.nav-dropdown {
    display: block;
    position: absolute;
    right: 0;
    min-width: 200px;
    top: 100%;
    transition: 0.4s;
    z-index: 10;
}

.nav-dropdown ul {
    border-radius: 16px 0 16px 16px;
    background-color: white;
}

.nav-dropdown-item {
    /* padding: 15px; */
cursor: pointer;
    color: #A1AEB6;
    display: block;
    white-space: nowrap;
    font-weight: 500;
}

.media-nav {
    display: none;
}

footer {
    padding-top: 50px;
    background-color: #151515;
    margin-top: 50px;
}

footer>* {
    font-size: 16px !important;
}

footer .container {
    margin-top: 0;
    row-gap: 20px;
}
.dashboard-dropdown,.dashboard-item {
    cursor: pointer;
}
footer .footer-top {
    display: flex;
    justify-content: center;
    width: 100%;
}

footer .footer-top .left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

footer .footer-top .left p {
    color: white;
    margin: 16px 0;
    text-align: center;
}

footer .footer-top .left .icons {
    display: flex;
    align-items: center;
    gap: 30px;
}
footer .footer-top .left .icons a {
    
    /* width: 50px; */
}
footer .footer-top .left .icons a i{
    font-size: 25px;
    color: white;
}

/* footer .footer-top .right {
    display: flex;
    width: 47%;
    justify-content: space-between;
}

footer .footer-top .right h4 {
    color: white !important;
    margin-bottom: 16px;
    font-weight: 500 !important;
} */

footer .footer-top .right li:not(:last-of-type) {
    margin-bottom: 12px;
}

footer .footer-top .right li a {
    color: #A0A0A0;
}

footer .map {
    width: 100%;
    /* margin-top: 50px; */
    text-align: right;
}
footer .map .contacts{
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
}
footer .map .contacts a{
    color: white;
}
footer .map  p {
    color: white !important;
    margin-bottom: 16px;
    display: flex;
    gap: 5px;
}
footer .map span{
    color: #A0A0A0;
}
footer .map .email{
    padding-top: 15px;
}
footer .map iframe {
    height: unset;
    width: 100%;
    border-radius: 16px;
    aspect-ratio: 5 / 2;
    vertical-align: middle;
}

footer .footer-bottom {
    padding: 24px;
    /* text-align: center; */
    width: 100%;
    display: flex;
    justify-content: center;
    transition: .5s;
}
footer .footer-bottom a:hover p{
    color: white;
    opacity: .8;

}
.kisa-melumat .picture {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 20 / 7;
    overflow: hidden;
    position: relative;
}

.kisa-melumat .picture .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
}

.slide.visible {
    opacity: 1 !important;
}

.slide.hidden {
    opacity: 0;
}

.kisa-melumat .picture .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    filter: blur(3px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.kisa-melumat .picture .text {
    width: 60%;
    margin: 12% 0;
    text-align: center;
    position: relative;
    z-index: 2;
}

.kisa-melumat .picture .text p {
    font-size: 16px;
    margin-top: 8px;
    color: #CBCBCB !important;
}

.kisa-melumat .picture .text h1 {
    color: white !important;
}
.logoMobile{
    display: none;
}
.footerLogo{
    width: 200px;
}
.footerLogo img{
    width: 100%;
    height: 100%;
}
.kisa-melumat .info {
    width: 100%;
    padding: 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.kisa-melumat .info .right {
    display: flex;
    align-items: center;
    gap: 30px;
   
}
.kisa-melumat .info .right .item {
    
    position: relative;
    
}
.kisa-melumat .info .right .item::after {
    content: '';
    position: absolute;
    top: 10px;
    right: -15px;
    width: 1px;
    height: 50%;
    background-color: #151515;
   
}



.kisa-melumat:hover .info h2,
.kisa-melumat:hover .info h3,
.teklifler:hover .left h1,
.about:hover .left h2,
.service-item:hover .text p {
    color: #019934;
    transition: 0.4s;
}

.teklifler .left {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
}

.teklifler .left p {
    margin: 16px 0;
}

.teklifler .left span {
    padding: 0 16px 16px;
    display: none;
    font-size: 14px;
    font-weight: 600;
    color: #019934;
}

.teklifler .left .photo-list {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 130px;
}

.teklifler .left .photo-list img {
    width: calc((100% - 32px) / 3);
    border-radius: 8px;
    height: 100%;
}

.teklifler .right {
    width: 100%;
    display: flex;
}

.teklifler .right img {
    height: 100%;
    margin: auto;
}

.zemanet {
    align-items: center;
    justify-content: space-between;
}

.zemanet .left {
    width: 46%;
    border-radius: 8px;
    overflow: hidden;
}

.zemanet .right {
    width: 46%;
    display: flex;
    flex-wrap: wrap;
}

.zemanet .right .item {
    width: 50%;
    padding: 20px;
}

.zemanet .right .item img {
    margin-bottom: 8px;
}

.fealiyyetler .mySwiper,
.related-topics .mySwiper {
    margin-top: 8px;
    padding: 10px;
}
.related-topics .swiper{
width: 100%;
}
.fealiyyetler .swiper{
width: 100%;
}
.activity-item {
    aspect-ratio: 3 / 2;
    position: relative;
}
/* .about .right{
    aspect-ratio: 1/2;
} */
.activity-item img {
    border-radius: 8px;
}

.activity-item .ust {
    border-radius: 0 0 8px 8px;
    padding: 16px;
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 4;
    overflow: hidden;
}
.image-preview{
    width: 100px;
    margin-top: 10px;
}
.image-preview img{
    width: 100%;
    height: 100%;
}
.image-preview a{
    color: #019934;
}
.activity-item .ust p {
    color: white !important;
}

.activity-item .ust p:first-of-type {
    /* text-transform: uppercase; */
    margin-bottom: 10px;
}

.activity-item .ust .bg-blur {
    backdrop-filter: blur(5px);
    position: absolute;
    top: -10px;
    bottom: -20px;
    left: -10px;
    right: -10px;
    z-index: -1;
    background-color: #15151540;
    transition: 0.4s;
}

.activity-item:hover .bg-blur {
    opacity: 0;
    transition: 0.4s;
}

.activity .activity-list {
    width: 100%;
    margin: 16px 0 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
}

.activity .activity-item {
    width: calc((100% - 48px) / 3);
}

.pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.pagination .item {
    height: 44px;
    min-width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 12px 16px;
    box-shadow: 0px 0px 8px 1px #26262629;
}

.pagination-active {
    box-shadow: 0px 0px 8px 1px #0199343D !important;
    color: #019934;
}

.about {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.about .left {
    /* width: 100%; */
    margin: 0 8% 0 4%;
}

.about .left h2 {
    font-size: 40px;
}
.about .left p{
    /* text-align: justify; */
}
.about .left p:first-of-type {
    margin: 16px 0;
}

.about .right {
    /* width: 100%; */
    aspect-ratio: 1/1;
    border-radius: 8px;
}

.faq-top {
    width: 50%;
    margin: 0 auto 24px;
    text-align: center;
    padding: 0 36px;
}

.faq-top h3 {
    margin-bottom: 4px;
}

.faq-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.faq-item {
    padding: 16px;
    border-radius: 16px;
    background-color: #EEFFE1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.6s;
}

.faq-item-active {
    background-color: #FFFFFF !important;
    border: 1px solid #2A3D68 !important;
}

.faq-item .left {
    overflow: hidden;
    max-height: 21px;
    margin-right: 20px;
}

.faq-item .left h4 {
    line-height: 21px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.faq-visible {
    max-height: 1000px !important;
}

.faq-visible h4 {
    display: block !important;
    transition: 0s;
    height: unset !important;
}

.faq-item .left p {
    margin-top: 4px;
}

.faq-btn {
    color: white;
    display: flex;
    padding: 8px;
    border-radius: 100%;
    background-color: #019934;
    transition: 0.6s;
    cursor: pointer;
}

.faq-btn-active {
    background-color: #F6F6F6 !important;
    color: #151515 !important;
}

.services,
.service-info {
    gap: 24px;
}
.kisa-melumat .info .right .item:last-child::after{
    width: 0;
    height: 0;
}
.services .right,
.service-info .right {
    width: calc((75% - 12px));
}

.services .right h3 {
    margin-bottom: 16px;
}

.services .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.services .service-item {
    width: calc((100% - 48px) / 3);
}

.services .pagination {
    margin-top: 24px;
}

.service-info .review {
    width: 100%;
    display: flex;
    gap: 2%;
}

.service-info .review .small-images {
    width: 13%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

}

.service-info .review .small-images .item {
    display: inline-flex;
    border-radius: 8px;
    width: 100%;
    height: unset;
    aspect-ratio: 10 / 11;
}

.service-info .review .big-image {
    width: 40%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
}

.service-info .review .text {
    width: 43%;
}

.service-info .review .text span {
    font-weight: 500;
    display: inline-block;
    color: #151515;
    margin-bottom: 8px;
}

.swiper-pagination {
    display: none;
}

.service-info .characteristics {
    padding: 8px;
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #CBCBCB;
    color: #151515;
}

.service-info .characteristics li {
    display: flex;
    justify-content: space-between;
}

.service-info .characteristics li:not(:last-of-type) {
    border-bottom: 1px solid #CBCBCB;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

/* SERVICE ITEM */
.service-item {
    display: block;
    /* height: 100%; */
}

.service-item .image {
    width: 100%;
    border: 8px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
}

.service-item .text {
    margin-top: 16px;
}

.service-item .text p {
    /* text-transform: uppercase; */
    max-height: 42px;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
}

/* SERVICE ITEM END*/

/* SERVICE FILTER */
.services-filter {
    width: calc((25% - 12px));
}

.filter-top {
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* text-transform: uppercase; */
    font-weight: 500;
    flex-wrap: wrap;
   
}
.filter-top span{
    cursor: pointer;
}
.filter-dropdown {
    width: 100%;
    padding: 4px;
    color: #606060 !important;
}

.filter-item {
    text-transform: none !important;
    padding: 4px 0;
    display: block;
}
/* Custom radio button styling */
.imageUrl-radio{
    display: flex;
    align-items: center;
    gap: 10px
}
.image-item input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #999;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  /* Checked state with green background */
 .image-item input[type="radio"]:checked {
    background-color: #28a745; /* Green color */
  }
  
  /* White circle in the middle when checked */
  .image-item input[type="radio"]:checked::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Hover state to change border color to green */
  .image-item input[type="radio"]:hover {
    border-color: #28a745;
  }
  .add-img-box{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .image-item{
    display: flex;
    /* gap: 15px; */
    flex-direction: column;
    row-gap: 10px;
    /* align-items: center; */
  }
  .image-item .imageInputs{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  /* .form-item textarea{
    max-width: 250px;
    min-width: 250px;
    min-height: 150px;
    max-height: 300px;
  } */
/* .filter-active {
    color: #019934;
} */
.EditProducts{
    position: fixed;
    z-index: 999;
    left: 20%;
    top: 0;
    width: 80%;
    height: 90%;
    overflow-y: scroll;
}
.EditProducts .add-product{
    width: 100%;
}
.editButtons{
    display: flex;
    align-items: center;
    gap: 20px;
}
.btnBagla{
    background: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 15px;
}
.btn-red{
    background-color: red;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    border: none;
    border-radius: 15px;
    margin: 10px 0;
    cursor: pointer;
}
.editProductselect{
    text-align: center;
    background-color: #ccc6c6;
    width: 100%;
    height: 40px;
    outline: none;
}
.deletebutton{
    border: none;
    background-color: transparent;
}
.select-item{
    display: flex;
    justify-content: space-between;
}
/* SERVICE FILTER  END*/
.top{
    display: flex;
    flex-direction: column;
}
.loadin_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    width: 100%;
}
.container_fealiyetler{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}
.container_fealiyetler img{
    width: 100%;
}
.bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
    width: 100%;
}
.follow-item img{
    width: 50px;
}
.popup{
    position: fixed;
    left: 20%;
    top: 0;
    width: 100%;
    overflow: scroll;
    height: 90%;
}
.popupMark{
    display: flex;
    justify-content: end;

}
.popupMark span{
    cursor: pointer;
    padding-right: 20px;
    font-size: 24px;
    font-weight: 500;
}
.partnership-images{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.bottoms{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
/* .partnership-container{
    display: flex;
    gap: 10px;
} */
.advantagecontainers{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 20px;
}
.form-bottom{
    padding-bottom: 20px;
}
.form-bottom .form-box{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
}
.form-bottom .form-box .formAz{
    display: flex;
    gap: 10px;
    align-items: center;
}
.forms_button{
    display: flex;
    align-items: center;
    gap: 30px;
}

/*  */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .categoryButtonsicon{
    display: flex;
    align-items: center;
    gap: 30px;

  }
  .categoryButtons{
    display: flex;
    align-items: center;
    gap: 15px;

  }
  .categoryButtonsicon button{
    border: none;
    background-color: transparent;
  }
@media (min-width: 992px) and (max-width: 1240px) {
    .container {
        width: 90%;
        min-width: unset;
    }
    nav .logo {
        width: 16%;
    }
    .nav-menu {
        width: 45%;
    }
    .nav-right {
        gap: 10px;
    }
    
}